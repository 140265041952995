import { useState, useEffect } from 'react';
import './servicesCard.css';
import Actionbutton from 'components/elements/buttons/button';

const ServicesCardStack = () => {

    const servicesList = [
        { title: 'Startups & Growth', color: "text-orange" },
        { title: 'Asset Financing', color: "text-brown" },
        { title: 'Invoice Discount', color: "text-secondary" },
        { title: 'Working Capital', color: "text-text-color" }];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setTimeout(() => {
            setIndex((prevIndex) => (prevIndex + 1) % servicesList.length);
        }, 4000);

        return () => clearTimeout(interval);
    }, [index,  servicesList.length]);


    return (

        <section class="services_card my-14 px-6 lg:px-20">
            <ul id="cards flex flex-col items-center justify-center">
                <li class="card bg-light-pink-1  bg-service-pattern flex flex-col lg:flex-row lg:space-x-48 py-6 lg:py-14 px-8 lg:px-14 rounded-lg " id="card1">
                    <div className="service-title basis-full lg:basis-11/12 text-text-color pt-1 lg:pt-8">
                        <h1 className="text-2xl lg:text-5xl text-text-color lg:leading-none tracking-normal text-orange font-semibold">We offer Loans that
                            <br className='display-none lg:inline'></br>Power  <span className={`font-serif italic ${servicesList[index].color}  font-semibold leading-normal`}>{servicesList[index].title}</span></h1>
                        <p className="pt-2 pb-4 lg:pt-6 lg:pb-6 text-dark-grey text-sm lg:text-base text-justify font-medium">We understand the unique challenges facing women entrepreneurs when it comes to funding, That's why we offer loans to help you take your business to new levels, with a minimum of 12 months of operation and a proven financial performance, your business is primed for expansion. Don't let limited resources hold you back.</p>

                        <Actionbutton to={"/loans"} text={"Learn More"} fontsize='text-normal' horzpadding='pt-2 pb-2 lg:py-4 px-8 lg:px-16' />
                    </div>
                    <div className="mt-4 px-2 lg:px-10 pt-4 rounded-t-full bg-light-orange-1 flex lg:items-end place-content-center">
                        <img
                            src="https://shecluded-medias.s3.us-east-2.amazonaws.com/hero-service-1.svg"
                            alt="illustration of woman holding money"
                            className="w-[120px] lg:w-[430px] object-cover rounded-lg"
                        />
                    </div>
                </li>
                <li class="card bg-light-purple bg-service-pattern flex flex-col lg:flex-row lg:space-x-48 py-6 lg:py-14 px-8 lg:px-14 rounded-lg " id="card2">
                    <div className="service-title basis-full lg:basis-11/12 text-text-color pt-1 lg:pt-8">
                    <h1 className="text-2xl lg:text-5xl text-text-color lg:leading-none tracking-normal font-semibold">
                                Elevate Your Enterprise <br></br> at <span className="font-serif italic text-primary font-semibold leading-normal">Shecluded Hub  </span></h1>
                                <p className="pt-2 pb-4 lg:pt-6 lg:pb-6 text-dark-grey text-base text-justify font-medium">We have a dedicated space created specifically for female entrepreneurs.
                                Think of it as a community center where women can come together to work, learn, and grow their businesses.</p>

                                <Actionbutton to={"/hub"} text={"Learn More"} fontsize='text-normal' horzpadding='py-2 lg:py-4 px-10 lg:px-16' />
                    </div>
                    <div className="mt-4 px-2 lg:px-10 pt-10 lg:pt-24 rounded-t-full  bg-primary-light-2  flex lg:items-end place-content-center">
                        <img
                            src="https://shecluded-medias.s3.us-east-2.amazonaws.com/hero-service-2.svg"
                                    alt="illustration of woman in orange facecap smiling"
                            className="w-[120px] lg:w-[430px] object-cover rounded-lg"
                        />
                    </div>
                </li>
                <li class="card bg-light-green-2 bg-service-pattern flex flex-col lg:flex-row lg:space-x-48 py-6 lg:py-14 px-8 lg:px-14 rounded-lg " id="card3">
                    <div className="service-title basis-full lg:basis-11/12 text-text-color pt-1 lg:pt-8">
                    <h1 className="text-2xl lg:text-5xl text-text-color lg:leading-none tracking-normal font-semibold">Fostering <span className="font-serif italic text-green font-semibold leading-normal">Business <br></br>
                                   Growth </span> with Shecluded</h1>
                                <p className="pt-2 pb-4 lg:pt-6 lg:pb-6 text-dark-grey text-base text-justify font-medium">Gain unparalleled exposure to top industry leaders and tackle impactful challenges in finance. Our accelerator program fast-tracks your career growth through hands-on experience and mentorship.</p>

                                <Actionbutton to={"/business-support"} text={"Learn More"} fontsize='text-normal' horzpadding='py-2 lg:py-4 px-10 lg:px-16' />
                    </div>
                    <div className="mt-4 px-2 lg:px-10 pt-4 lg:pt-14 rounded-t-full   bg-light-green-3  flex lg:items-end place-content-center">
                        <img
                          src="https://shecluded-medias.s3.us-east-2.amazonaws.com/hero-service-3.svg"
                                    alt="illustration of waering hijab smiling"
                            className="w-[120px] lg:w-[430px] object-cover rounded-lg"
                        />
                    </div>
                </li>
            </ul>
        </section>
    );
};

export default ServicesCardStack;
