
import NavBar from "components/layout/navbar";

export default function Header() {
    return (
        <header className="bg-white">
            <NavBar />
        </header>
    );

}
