import Actionbutton from 'components/elements/buttons/button';


export default function EmptyMain() {
    return (
       <section className="404 mt-14 px-6 " >
        <div className="flex flex-col items-center justify-center text-center">
             <p className="text-base text-dark-grey-1 pt-2 font-bold text-xl">404</p>
             <h1 className="text-4xl  lg:text-5xl lg:text-7xl text-primary tracking-normal font-medium pt-4">Page Not 
              Found </h1>
              <p className="px-4 lg:px-0 text-base text-dark-grey-1 pt-4 lg:pt-2 text-lg">Sorry, we couldn’t find the page you’re looking for.</p>
            
              <div className="lg:flex lg:flex-1 items-center g:justify-end mt-4">
         
          <Actionbutton to={'/'} text={"Go to HomePage"} />
          
        </div>
             <div className="404-illu mt-6">
                <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/not_found.png" alt='woman with laptop and error page exclamation '></img>
             </div>
             </div>
       </section> 
    );

}

