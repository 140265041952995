import React from "react";
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import NavBar from "components/layout/navbar";
import HeroImageCarousel from "./scrollingHeroImages";

export default function Header() {
    return (
        <header className="bg-primary-light-4 bg-hero-pattern">
            <NavBar />

            <div className="flex flex-col items-center justify-center w-full mx-auto hero-text py-10 lg:py-16 px-4 lg:px-0">
              
                <h1 className="lg:max-w-4xl max-w-sm text-4xl lg:text-6xl text-center text-text-color tracking-normal font-medium">Supporting <span className="font-serif italic text-primary font-semibold">Women's Vision </span>
                    Beyond Financing</h1>
                    
                <p className="max-w-sm px-4 lg:px-0  lg:max-w-lg text-center py-6 text-dark-grey text-base md:text-lg font-medium">We’re a robust ecosystem that empowers women to overcome the financing challenges they face by providing financial solutions, mentorship, and access to a supportive network.</p>

                <a href="/loans" className="inline-flex items-center rounded-full bg-black-1 py-4 px-16 text-sm/6 text-lg font-medium text-light-grey-4 drop-shadow-lg  transition duration-0 ease-in hover:drop-shadow-xl hover:duration-150">
                    Get Started
                    <ChevronRightIcon className="ml-1 h-5 w-5 text-gray-500" aria-hidden="true" />
                </a>
            </div>
            <HeroImageCarousel />

        </header>

    )
}

