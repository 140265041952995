import React from "react";



export default function JoinCommunityBanner({background='bg-text-color', textColor='text-light-grey-2', btnColor='bg-white text-text-color'}) {
    return (
        <section className="community-banner mt-6 lg:mt-14 py-2 px-6 lg:px-14">
            <div className={`flex flex-col items-center justify-center ${background} bg-service-pattern rounded-lg py-10 lg:py-20 px-6 lg:px-56`}>
                <button class="rounded-full bg-white/20 px-4 text-sm md:text-base font-medium py-2 text-white text-center">Don’t miss out!!!</button>
                <h1 className={`text-4xl lg:text-6xl font-bold ${textColor} text-center mt-6`}>Be Part of a<br></br> Transformative Network</h1>
                <p className="text-center pt-3 pb-6 lg:py-6 px-6 lg:px-24 text-white text-sm md:text-lg font-medium">Sign up now to connect with a vibrant network of women entrepreneurs
                    who are passionate about growth and collaboration.</p>
                <a href="https://chat.whatsapp.com/KNjaEnbf4zl5NFRKcTrsLW" className={`inline-flex items-center rounded-full ${btnColor} py-4 px-10 lg:px-16 text-sm lg:text-base font-semibold drop-shadow-lg text-center transition duration-0 ease-in hover:drop-shadow-xl hover:duration-150`}>
                    Join Community
                </a>
            </div>
        </section>
    )
}

